var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('ModalVue',{ref:"Modal",attrs:{"id":"copyShopifyProductsVariants","title":"Sync variants as products","size":"lg"}},[_c('div',{staticClass:"my-2",class:!_vm.isLoadingSync ? 'd-none' : 'd-block'},[_c('ProgressbarVue',{ref:"progressbar"}),_c('span',{staticClass:"text-success text-center",staticStyle:{"font-size":"12px","text-align":"center"}},[_vm._v(" Products syncing soon, continue tasks uninterrupted. ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-12"},[_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('FilterBox',{staticStyle:{"flex":"1"},attrs:{"search":true,"placeholder":"Search Products by title or SKU"},on:{"input":function($event){return _vm.searchProductQueryHandle($event)}}})],1)])]),_c('div',{staticClass:"loader px-2"},[(_vm.isLoadingShopifyProducts)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{staticStyle:{"color":"#4d1b7e"},attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Loading Products")])],1):_vm._e()]),(_vm.isLoadingShopifyProductFirstTime)?_c('b-skeleton-table',{attrs:{"rows":5,"columns":3,"table-props":{ bordered: true, striped: true }}}):(_vm.shopifyProducts.length)?_c('div',{staticClass:"data-table"},[_c('div',{staticStyle:{"max-height":"52vh","min-height":"52h","overflow":"auto"}},[_c('b-table',{staticClass:"leadTable",attrs:{"sticky-header":true,"no-border-collapse":true,"head-class":"text-center","stickyColumn":true,"hover":"","fields":_vm.shopifyProductsFields,"items":_vm.shopifyProducts},scopedSlots:_vm._u([{key:"head(checkbox)",fn:function(){return [_c('b-form-checkbox',{staticClass:"checkbox selectAll",attrs:{"size":"lg","disabled":_vm.isLoadingShopifyProducts ||
                  _vm.isLoadingSelectedShopifyProducts ||
                  _vm.isLoadingSync},on:{"change":function($event){return _vm.shopifyProductSelectAllToggle($event)}},model:{value:(_vm.shopifySelectAllCheck),callback:function ($$v) {_vm.shopifySelectAllCheck=$$v},expression:"shopifySelectAllCheck"}})]},proxy:true},{key:"cell(checkbox)",fn:function(data){return [_c('b-form-checkbox',{staticClass:"checkbox",attrs:{"value":data.item,"disabled":_vm.isLoadingShopifyProducts ||
                  _vm.isLoadingSelectedShopifyProducts ||
                  _vm.isLoadingSync,"size":"lg"},model:{value:(_vm.shopifySelectedProducts),callback:function ($$v) {_vm.shopifySelectedProducts=$$v},expression:"shopifySelectedProducts"}})]}},{key:"cell(pic)",fn:function(data){return [_c('div',{staticClass:"d-flex gap align-items-center"},[_c('lazy-image',{key:data.index+data.item.displayName,staticClass:"circle-image mr-2",attrs:{"src":data.item.image? data.item.image: 'https://images.quizell.com/website/default_start_page.png'}}),_c('div',{},[_vm._v(_vm._s(data.item.displayName))])],1)]}}])})],1)]):[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"50vh"}},[_c('EmptyTable',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" No product found ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Time to fill your shelves or refine your search. Remember, your results will appear right here. ")]},proxy:true}])})],1)],_c('div',{staticClass:"d-flex justify-content-between mt-2"},[_c('ShopifyPagination',{attrs:{"hasNext":_vm.pageInfo.hasNextPage,"hasPrevious":_vm.pageInfo.hasPreviousPage},on:{"onNext":() => {
              _vm.queryObject.before = '';
              _vm.queryObject.after = _vm.pageInfo.endCursor;
              _vm.getProductsFromShopify();
            },"onPrevious":() => {
              _vm.queryObject.after = '';
              _vm.queryObject.before = _vm.pageInfo.startCursor;
              _vm.getProductsFromShopify();
            }}}),_c('div',{},[_c('div',{},[(_vm.shopifySelectedProducts.length)?_c('BorderButton',{staticClass:"secondary-color-button mr-3",attrs:{"custom":true,"disabled":_vm.isLoadingSync ||
                _vm.isLoadingShopifyProducts ||
                _vm.isLoadingSelectedShopifyProducts || _vm.shopifySelectedProducts.length<=0,"isLoading":_vm.isLoadingSelectedShopifyProducts},on:{"click":function($event){return _vm.syncSelected('')}}},[_vm._v("Sync Selected")]):_c('button',{staticClass:"btn d-flex px-3 justify-content-center align-items-center py-2 shopify-btn",attrs:{"disabled":_vm.isLoadingSync ||
                _vm.isLoadingShopifyProducts ||
                _vm.isLoadingSelectedShopifyProducts},on:{"click":function($event){return _vm.syncSelected('all')}}},[(_vm.isLoadingSync||_vm.isLoadingSelectedShopifyProducts)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-1"},[_vm._v("Syncing...")])],1):_c('span',[_vm._v("Sync All")])])],1)])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }